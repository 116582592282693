import axios from "axios";
import { useAxios } from "./useAxios";

const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    // cast result to string due to we call readAsDataUrl which return "data:URL"
    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

const handleDownloadedFile = async (blob: Blob, displayMode: "view" | "download", fileName: string, fileType: "general" | "vision") => {
  // create file link in browser's memory
  const href = URL.createObjectURL(
    new Blob(
      [blob],
      {
        type: `${blob.type};charset=utf-8`
      }
    )
  );

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  if(displayMode === "download") {
    // add download attribute
    link.setAttribute('download', fileName);
  }
  else if(displayMode === "view") {
    if(fileType === 'general') {
      // open in new tab
      link.setAttribute('target', "_blank");
    }
    else if(fileType === "vision") {
      return await blobToBase64(blob)
    }
    else {
      throw new Error("Invalid file type");
    }
  }
  else{
    throw new Error("Invalid display mode");
  }
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

/**
 * downloadFileFromBackend is a method to download specific file from backend server
 * 
 * @param {number} fileId: target file's id
 * @param {"encode" | "decode"} type: type of file to download
 */
export const downloadFileFromBackend = async (
  fileId: number,
  type: "original" | "decoded",
  displayMode: "view" | "download" = "download"
): Promise<string | void> => {
  const { instance: axiosInstance } = useAxios()

  // compute endpoint
  let endpoint = ""
  if(type === "original") {
    endpoint = `data_management/download/original`
  }
  else {
    endpoint = `data_management/download`
  }

  try {
    // call download api
    const response = await axiosInstance.post(endpoint,
      {
        file_id: fileId
      },
      {
        responseType: "blob"
      }
    )
    // compute file name and file type
    const fileName = decodeURIComponent(atob(response.headers["file-name"]).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const fileType = response.headers["file-type"];

    return await handleDownloadedFile(response.data, displayMode, fileName, fileType)
  }
  catch(error) {
    // check is axios error
    if(axios.isAxiosError(error)) {
      if(error.response !== undefined && error.response.data.status === false) {
        throw new Error(error.response.data.description)
      }
    }
    throw new Error("We apologize, but an unknown error occurred while downloading your file. Please try again later or contact our support team for assistance.")
  }
}

export const getGeneratedImage = async(imagePath: string): Promise<{blob: Blob; fileName: string; fileType: string}> => {
  const { instance: axiosInstance } = useAxios()
  try {
    const imageUrl = `data_management/download/generated-image`
    // call download api
    const response = await axiosInstance.get(imageUrl,
      {
        params: {
          image_key: imagePath
        },
        responseType: "blob"
      },
    )
    
    return {
      blob: response.data,
      fileName: decodeURIComponent(
        atob(response.headers["file-name"])
          .split("")
          .map(function (c) {
            return (
              "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
            );
          })
          .join("")
      ),
      fileType: response.headers["file-type"],
    };
  } catch (error) {
    console.error(error);
    throw new Error(
      "We apologize, but an unknown error occurred while downloading your file. Please try again later or contact our support team for assistance."
    );
  }
}