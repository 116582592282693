import { it } from 'vitest'
import { defineStore } from 'pinia'
import { useAxios } from '@/utilities/useAxios'
import { ref } from 'vue'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const accessToken = ref()
    const refreshToken = ref()
    const displayName = ref()
    const role = ref()
    const isRefreshingToken = ref(false)

    const login = async (credentials: any) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/login', credentials)
        // Handle successful login, e.g., store token, update state

        const response_data = response.data
        const two_factor_required = response_data.two_factor_required

        if (two_factor_required) {
          const ref_code = response_data.ref_code
          return ref_code
        }

        accessToken.value = response_data.access_token
        refreshToken.value = response_data.refresh_token
        displayName.value = response_data.full_name
        role.value = response_data.role
        return null
      } catch (error) {
        logout()
        throw error
      }
    }

    const verify2FA = async (credentials: any) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/verify', credentials)
        // Handle successful login, e.g., store token, update state

        const response_data = response.data

        accessToken.value = response_data.access_token
        refreshToken.value = response_data.refresh_token
        displayName.value = response_data.full_name
        role.value = response_data.role
      } catch (error) {
        logout()
        throw error
      }
    }

    const logout = async () => {
      const { instance: axiosInstance } = useAxios()

      try {
        await axiosInstance.post('account/logout')
      } catch (error) {
        throw error
      } finally {
        //Remove Token
        accessToken.value = undefined
        refreshToken.value = undefined
        displayName.value = undefined
        role.value = undefined
        isRefreshingToken.value = false
      }
    }

    const googleLoginCallback = async (callbackParams: any) => {
      console.log(callbackParams)
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/google/login/callback', {
          credential: callbackParams.credential,
          code: callbackParams.code
        })
        const response_data = response.data
        const two_factor_required = response_data.two_factor_required

        if (two_factor_required) {
          const ref_code = response_data.ref_code
          return ref_code
        }

        accessToken.value = response_data.access_token
        refreshToken.value = response_data.refresh_token
        displayName.value = response_data.full_name
        role.value = response_data.role
      } catch (error) {
        logout()
        throw error
      }
    }

    const refresh = async () => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/refresh-token', {
          refresh_token: refreshToken.value
        })

        const response_data = response.data

        accessToken.value = response_data.access_token
        refreshToken.value = response_data.refresh_token
      } catch (error) {
        throw error
      }
    }

    const isAllowedToChangePassword = async () => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.get('account/change_password/is-allow')

        return response.data.status as Boolean
      } catch (error) {
        throw error
      }
    }

    const sendProblemReport = async (body: { topic: string; description?: string }) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/save_report', body)
        return response.data
      } catch (error) {
        throw error
      }
    }

    const changePassword = async (body: {
      old_password: string
      new_password: string
      confirm_password: string
    }) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/change_password', body)

        return response.data
      } catch (error) {
        throw error
      }
    }

    const sendEmailForgetPassword = async (body: { email: string; frontend_origin: string }) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/forgot-password', body)

        return response.data
      } catch (error) {
        throw error
      }
    }

    const getTwoFactorCompanySetting = async () => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.get('account/two-factor-company')

        return response.data
      } catch (error) {
        throw error
      }
    }

    const updateTwoFactorCompanySetting = async (body: {
      days_to_next_check: number
      is_enabled: boolean
      is_google_login_enabled: boolean
    }) => {
      const { instance: axiosInstance } = useAxios()

      try {
        const response = await axiosInstance.post('account/two-factor-company/update', body)

        return response.data
      } catch (error) {
        throw error
      }
    }

    return {
      accessToken,
      refreshToken,
      displayName,
      role,
      isRefreshingToken,
      login,
      logout,
      refresh,
      googleLoginCallback,
      isAllowedToChangePassword,
      sendProblemReport,
      changePassword,
      sendEmailForgetPassword,
      verify2FA,
      getTwoFactorCompanySetting,
      updateTwoFactorCompanySetting
    }
  },
  {
    persist: {
      pick: ['accessToken', 'refreshToken', 'displayName', 'role']
    }
  }
)
